
export default {
	name: "CoreBlockCalendly",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		calendlyUrl() {
			let eventType = this.settings.eventType;
			let calURL = "";

			if (eventType === "Custom Event") {
				calURL = `https://calendly.com/${this.settings.calendlyId}/${this.customEvent}?utm_campaign=marketing&utm_source=agentwebsites`;
			} else if (eventType) {
				eventType = eventType.toLowerCase().replace(/\s/g, "");
				calURL = `https://calendly.com/${this.settings.calendlyId}/${eventType}?utm_campaign=marketing&utm_source=agentwebsites`;
			} else {
				calURL = `https://calendly.com/${this.settings.calendlyId}/?utm_campaign=marketing&utm_source=agentwebsites`;
			}

			return calURL;
		},
		customEvent() {
			const customEvent = this.settings.customEvent;
			const eventType = this.settings.eventType;
			if (customEvent && eventType === "Custom Event") {
				return customEvent;
			}
			switch (eventType) {
				case "General Discussion":
					return "generaldiscussion";
				case "Intro Appointment":
					return "introappointment";
				case "Review":
					return "review";
				case "Intro to NYL":
					return "introtonyl";
				case "Quick Checkin":
					return "quickcheckin";
				case "First Interview":
					return "firstinterview";
				default:
					return "";
			}
		},
		buttonText() {
			return this.settings.buttonText;
		},
		getDisplayType() {
			return this.settings.displayType;
		},
		primaryBtn() {
			return (
				{
					type: "button",
					label: this.buttonText,
					newTab: this.settings.primaryBtnNewTab,
					event: this.showPopUp,
				} || null
			);
		},
	},
	methods: {
		showPopUp() {
			this.GTMCalendly();
			if (process.client) {
				window.Calendly.showPopupWidget(this.calendlyUrl);
				this.setCalendlyIframeTitle(this.settings.iframeTitle);
				this.addModalListener();
				this.focusPopup();
			}
			return false;
		},
		focusPopup() {
			// Calendly uses an iframe, it can't be manipulated because of
			// same-origin browser's policy.
			const container = document.querySelector(".calendly-popup-content");
			if (!container) {
				return;
			}
			const button = document.createElement("button");
			container.prepend(button);
			button.focus();
			button.remove();
		},
		setCalendlyIframeTitle(title) {
			// We capture all the iframes in case DOM has more than the calendly iframe.
			const iframes = document.querySelectorAll("iframe");
			for (const iframe of iframes) {
				if (iframe.src?.includes("https://calendly.com")) {
					iframe.setAttribute(
						"title",
						title || "default-calendly-title"
					);
				}
			}
		},
		closeModalKeyHandler(event) {
			// Key pressed handler.
			if (event.key === "Escape" || event.keyCode === 27) {
				this.closeCalendlyModal();
			}
		},
		closeCalendlyModal() {
			// Close the modal and remove the listener
			window.Calendly.closePopupWidget();
			document.removeEventListener(
				"keydown",
				this.closeModalKeyHandler,
				true
			);
		},
		addModalListener() {
			// Creates the listener
			document.addEventListener(
				"keydown",
				this.closeModalKeyHandler,
				true
			);
		},
		GTMCalendly() {
			const { eventType, calendlyId } = this.decodeCalendlyUrl(
				this.settings.url
			);
			this.$gtm.push({
				event: "nyl-calendly",
				calendly_id: calendlyId,
				calendly_category: this.getCalendlyCategory(eventType),
				event_type: eventType,
				calendly_event_id: eventType?.toLowerCase().replace(/\s/g, ""),
				click_url: this.settings.url,
			});
		},
		decodeCalendlyUrl(url) {
			if (!url || typeof url !== "string" || url.trim() === "") {
				return {
					eventType: null,
					calendlyId: null,
				};
			}

			try {
				const urlObj = new URL(url);
				const pathParts = urlObj.pathname
					.split("/")
					.filter((part) => part !== "");
				const calendlyId = pathParts[0] || null;
				let eventType = "";

				if (pathParts.length > 1) {
					eventType = decodeURIComponent(pathParts[1]);
				}

				if (
					!eventType ||
					(eventType.includes("utm_campaign") &&
						eventType.includes("utm_source"))
				) {
					eventType = null;
				}

				return {
					eventType,
					calendlyId,
				};
			} catch (error) {
				console.error("Invalid Calendly URL:", error);
				return {
					eventType: null,
					calendlyId: null,
				};
			}
		},
		getCalendlyCategory(eventType) {
			var calendlyCategory = "";
			switch (eventType) {
				case "Custom Event":
					calendlyCategory = "custom";
					break;
				case "General Discussion":
				case "Intro Appointment":
				case "Review":
					calendlyCategory = "agent";
					break;
				case "Intro to NYL":
				case "Quick Checkin":
				case "First Interview":
					calendlyCategory = "GO";
					break;
			}
			return calendlyCategory;
		},
	},
};
